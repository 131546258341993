<template>
  <el-container>
    <el-header height="118px">
      <Header :typeFu="2"></Header>
      <div class="notify flex" v-if="$store.state.isDisplay_num == 1">
        <div class="flex">
          <img alt="" class="notify_img" src="../../../assets/img/notify.png"/>
        </div>
        <div>注意：本次活动报名有人数限制，当前共有{{ info.order_num }}人申请，剩余名额为{{info.student_num}}人</div>
      </div>
      <div class="bg-top"></div>
    </el-header>
    <el-container>
      <div class="com-container index-container">
        <div class="title">{{ info.name }}</div>
        <!--        <div v-html="info.student_recruitment_brochure"></div>-->
        <!--        <div class="btn" v-if="info.name">-->
        <!--          <el-button @click="toLogin()">立即报名</el-button>-->
        <!--        </div>-->
        <div class="active-area">
          <div class="left-area card-box" v-if="anchorList.length>0">
            <div class="left-area-box">
              <ul class="left">
                <li :key="index" v-for="(item,index) in anchorList">
                  <a @click="anchorSkip(item.id)">{{item.name}}</a>
                </li>
              </ul>
            </div>
          </div>
          <div :style="{
          'min-height':minHeight+'px'
        }" class="center-area card-box">
            <div class="center-area-cont">
              <div v-html="info.student_recruitment_brochure"></div>
            </div>
          </div>
        </div>
      </div>
      <Footer></Footer>
    </el-container>
  </el-container>
</template>
<script>
  /* eslint-disable */
  import Header from "@/components/Header.vue";
  import Footer from "@/components/index/Footer.vue";
  import {getCamInfo} from "r/index/login";

  export default {
    name: "recruit",
    data() {
      return {
        info: {},
        anchorList: [],
        minHeight: '339',
      };
    },
    created() {
      this.info = this.$store.state.info;
      console.log(this.info)
      setTimeout(() => {
        this.anchorList = this.getAnchor(this.info.student_recruitment_brochure);
      }, 1000)
      let code = this.$store.state.indexIdentity;
      //判断是否关闭了营地(关闭了不需要调用setlog)
      let isCloseCamp = this.$store.state.info.on_off;
      if (isCloseCamp == 1) {
        getCamInfo(code).then((res) => {
          document.title = res.data.name;
          return false;
        });
        this.commonApi(29)
      }

    },
    methods: {
      toLogin() {
        let code = this.$store.state.indexIdentity;
        if (!window.localStorage.getItem("index-token-all")) {
          this.$router.push({
            path: "/login",
            query: {
              ...this.$store.state.query,
              code: code
            }
          });
        } else {
          this.$router.push({
            path: "/signUp/undefined",
            query: {
              ...this.$store.state.query,
              code: code
            }
          });
        }
      },
      // 锚点跳转方法
      anchorSkip(id) {
        document.querySelector(id).scrollIntoView({
          behavior: 'smooth',
          block: 'center'
        });
      },
      // 分离获取html中的锚点
      getAnchor(content) {
        if (!content) return [];
        let attrAndValueArr = content.match(/<a id=\"(.*?)\"><\/a>/g);
        let valueArr = [] // 放所有该属性的值
        let anchorList = [];
        if (attrAndValueArr && attrAndValueArr.length > 0) {
          for (let i = 0; i < attrAndValueArr.length; i++) {
            valueArr.push(attrAndValueArr[i]
              .replace(/<a id=/g, '')
              .replace(/\"/g, '')
              .replace(/><\/a>/g, ''));
          }
          valueArr.forEach((item, index) => {
            let father = document.getElementById(item).parentNode;
            let value = father.innerHTML.replace(/<(.*?)>/g, '').replace(/<\/(.*?)>/g, '');
            if (value && value.length < 10) {
              anchorList.push({
                id: '#' + item,
                name: value,
              })
            }
          })
          return anchorList;
        }
        return [];
      },
    },
    components: {
      Header,
      Footer,
    },
  };
</script>
<style lang="scss">
  @import "a/scss/common";

  .flex {
    display: flex;
  }

  .el-container {
    position: relative;
    width: 100%;
    min-height: 100%;
    min-width: 1150px;
    background-color: #f8f8f8;

    .el-header {
      position: fixed;
      width: 100%;
      padding: 0;
      background-color: #ffffff;
      z-index: 2000;

      .notify {
        background-color: #fbf8fb;
        height: 38px;
        align-items: center;
        padding-left: 5%;
      }

      .notify_img {
        width: 20px;
        margin-right: 5px;
      }
    }

    .el-container {
      width: 100%;
      display: flex;
      flex-flow: column;
      margin-top: 118px;

      .index-container {
        flex: 1;
        padding-bottom: 50px;

        .title {
          padding: 52px 0 20px 0;
          font-size: 34px;
          text-align: center;
        }

        .time {
          font-size: 12px;
          font-weight: 500;
          color: #828282;
          text-align: center;
        }

        .paper {
          text-align: center;
          margin: 8px 0 24px 0;

          img {
            width: 654px;
            height: 368px;
          }
        }

        .part {
          span {
            font-size: 16px;
            font-weight: 600;
            color: #333333;
          }

          p {
            font-size: 16px;
            font-weight: 500;
            color: #666666;
            line-height: 32px;
          }
        }

        .btn {
          text-align: center;
          padding-bottom: 50px;

          .el-button {
            width: 200px;
            height: 50px;
            background: var(--color);
            border-radius: 4px;
            opacity: 0.8;
            line-height: 50px;
            text-align: center;
            color: #fff;
            font-size: 16px;
            padding: 0;
            margin: 30px 0 0 0;
          }
        }

      }
    }
  }

  .active-area {
    display: flex;
    flex-flow: row;
    align-items: self-start;
    justify-content: space-between;
    position: relative;

    .left-area {
      flex: 1;
      min-width: 200px;
      background-color: #ffffff;
      border-radius: 8px;

      .left-area-box {
        border-radius: 8px;
        background-color: #ffffff;
      }

      ul {
        padding: 20px;
      }

      li {
        list-style: none;

        a {
          display: inline-block;
          text-decoration: none;
          color: #333333;
          width: 100%;
          font-size: 18px;
          /*height: 44px;*/
          line-height: 44px;
          margin: 10px 0;
          text-align: center;
          border-bottom: 1px solid #cccccc;
          cursor: pointer;
          border-left: 4px solid transparent;

          &:hover,
          &.is-active {
            color: var(--all_color);
            background-color: #f5f5f5;
            border-left: 4px solid var(--all_color);
          }
        }

      }

    }

    .center-area {
      flex: 4;
      margin: 0 20px;
      width: calc(100% - 100px);
      border-radius: 8px;
      background-color: #ffffff;

      .center-area-title {
        text-align: center;
        font-size: 22px;
        font-weight: 500;
        height: 50px;
        padding: 10px 20px 0;

        .center-area-title-box {
          height: 50px;
          display: flex;
          flex-flow: row;
          justify-content: left;
          border-bottom: 1px solid #cccccc;

          .center-area-title-box-item {
            margin: 0 20px;
            height: 40px;
            line-height: 40px;
            cursor: pointer;

            &:hover {
              border-bottom: 2px solid #eeeeee;
            }

            &.is-active {
              border-bottom: 2px solid var(--all_color);
            }
          }
        }
      }

      .center-area-cont {
        padding: 20px 30px;
        min-height: 238px;
      }
    }

    .right-area {
      flex: 1.5;
      min-width: 320px;

      .question-area {

        .question-area-title {
          text-align: center;
          font-size: 22px;
          font-weight: 500;
          line-height: 50px;
          padding: 10px 20px 0;

          .question-area-title-box {
            border-bottom: 1px solid #cccccc;
          }
        }

        .question-area-cont {
          padding: 10px 25px 20px;

          .ask-answer-item {
            padding: 10px 5px;
            font-size: 14px;
            line-height: 30px;
            border-bottom: 1px solid #cccccc;
          }

          ::v-deep .el-empty {
            padding: 10px 0;

            .el-empty__image {
              width: 100px;
            }
          }

          .question-email {
            font-size: 14px;
            line-height: 30px;
            margin-top: 20px;
          }
        }
      }

      .back-area {
        .back-area-title {
          text-align: center;
          font-size: 22px;
          font-weight: 500;
          line-height: 50px;
          padding: 10px 20px 0;
        }

        .back-area-item {
          padding: 15px 10px;
          display: flex;
          flex-flow: row;
          margin-bottom: 10px;

          .back-area-item-cover {
            flex: 1;
            height: 88px;
            overflow: hidden;
            border-radius: 4px;

            ::v-deep .el-image {
              width: 100%;
              height: 100%;
              border-radius: 4px;
              transition: all .5s;
              -webkit-transition: all .5s;

              .el-image__error {
                background-color: #D8D8D8;
              }
            }
          }

          .back-area-item-cont {
            flex: 1;
            margin-left: 10px;
            display: flex;
            flex-flow: column;
            justify-content: space-around;

            .back-area-item-cont-txt {
              font-size: 12px;
              line-height: 18px;

              &.color_2 {
                color: #999999;
              }

              &.color_3 {
                color: #EC808D;
              }

              .sign-box {
                display: inline-block;

                .sign {
                  display: inline-block;
                  padding: 0 10px;
                  border-radius: 4px;
                  background-color: #eeeeee;
                  margin: 2px 4px 2px 0;

                  &:last-child {
                    margin-right: 0;
                  }
                }
              }

            }
          }

          &:hover {
            cursor: pointer;
            box-shadow: 6px 6px 15px rgba(0, 0, 0, 0.1);

            .el-image {
              transform: scale(1.1);
              -ms-transform: scale(1.1); /* IE 9 */
              -moz-transform: scale(1.1); /* Firefox */
              -webkit-transform: scale(1.1); /* Safari 和 Chrome */
              -o-transform: scale(1.1);
            }
          }
        }
      }
    }
  }
</style>
